import styled, { css } from "styled-components";
import { layout, space } from "styled-system";
import Button from "..";
import { PrimaryButtonProps } from "./types";

export const PrimaryStyledButton = styled(Button)<PrimaryButtonProps>`
  ${space}
  ${layout}
  ${({ color }) =>
    color === "default" &&
    css`
      background-color: ${({ theme }) => theme.colors.primary[600]};
      color: ${({ theme }) => theme.colors.base.white};
      &:hover {
        background-color: ${({ theme }) => theme.colors.primary[700]};
        box-shadow: 0px 16px 10px rgba(0, 0, 0, 0.02),
          0px 7px 7px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03),
          0px 0px 0px rgba(0, 0, 0, 0.03);
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.primary[800]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.primary[200]};
      }
    `}

  ${({ color }) =>
    color === "secondary" &&
    css`
      background-color: ${({ theme }) => theme.colors.primary[50]};
      color: ${({ theme }) => theme.colors.primary[800]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary[100]};
        color: ${({ theme }) => theme.colors.primary[900]};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.primary[50]};
        color: ${({ theme }) => theme.colors.primary[800]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.primary[25]};
        color: ${({ theme }) => theme.colors.primary[300]};
      }
    `}

    ${({ color }) =>
    color === "tertiary" &&
    css`
      color: ${({ theme }) => theme.colors.primary[800]};
      &:hover {
        background-color: ${({ theme }) => theme.colors.primary[50]};
        color: ${({ theme }) => theme.colors.primary[900]};
      }
      &:active {
        background-color: unset;
        color: ${({ theme }) => theme.colors.primary[800]};
      }
      &:disabled {
        background-color: unset;
        color: ${({ theme }) => theme.colors.primary[300]};
      }
    `}
    
    ${({ variant }) =>
    variant === "text" &&
    css`
      background-color: unset;
      color: ${({ theme }) => theme.colors.primary[800]};

      &:hover {
        background-color: unset;
        color: ${({ theme }) => theme.colors.primary[900]};
      }
      &:active {
        background-color: unset;
        color: ${({ theme }) => theme.colors.primary[800]};
      }
      &:disabled {
        background-color: unset;
        color: ${({ theme }) => theme.colors.primary[300]};
      }
    `}

      ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
